/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";



strong{
    font-weight: bold;
}

.text-center{
    text-align: center;
}

.text-right{
    text-align: right !important;
}

.text-left{
    text-align: left;
}

.float-right{
    float: right;
}

.clickeable:hover{
    cursor: pointer;
}

.logo{
    height: 25vh;
}

:root {
    --base-blue: #23425c;
    --base-grey: #ededed;
    --base-white: #ffffff;
    --base-accent-blue:#8aa4c2;
}

:root {
    --ion-color-green: #5ab129;
    --ion-color-green-rgb: 90, 177, 41;
    --ion-color-green-contrast: #FFFFFF;
    --ion-color-green-contrast-rgb: 0, 0, 0;
}
.ion-color-green {
    --ion-color-base: var(--ion-color-green) !important;
    --ion-color-base-rgb: var(--ion-color-green-rgb) !important;
    --ion-color-contrast: var(--ion-color-green-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-green-shade) !important;
    --ion-color-tint: var(--ion-color-green-tint) !important;
}


.container-content {
    text-align: center;
}

.button-container{
    margin:1vh 0 1vh 0;
    text-align: center;
}
.button-container ion-button{
    margin: 0 10px 0 10px;
}

.large-button{
    width: 70%;
    margin:1vh 0 1vh 0;
}

ion-content{
    --background: #fff url('/assets/app_background.jpg') no-repeat center center / cover;
}